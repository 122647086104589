import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Trailguide AS (under avvikling)`}<br />{`
Tuppeskogvegen 477`}<br />{`
3550 Gol, Norway`}</p>
    <br />
    <p>{`Represented by Bjørn Jarle Kvande`}</p>
    <br />
    <p>{`bjorn.jarle.kvande (at) trailguide.no`}</p>
    <p>{`+47 916 36 159`}</p>
    <br />
    <p>{`Contact for GDPR and responsible for editorial content:`}</p>
    <p>{`Bjørn Jarle Kvande`}</p>
    <p>{`bjorn.jarle.kvande (at) trailguide.no`}</p>
    <p>{`+47 916 36 159`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      